exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-commenting-rules-jsx": () => import("./../../../src/pages/commenting-rules.jsx" /* webpackChunkName: "component---src-pages-commenting-rules-jsx" */),
  "component---src-pages-markdown-remark-fields-permalink-jsx": () => import("./../../../src/pages/{markdownRemark.fields__permalink}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-fields-permalink-jsx" */),
  "component---src-templates-categories-jsx": () => import("./../../../src/templates/categories.jsx" /* webpackChunkName: "component---src-templates-categories-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

